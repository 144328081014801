<template>
	<div class="padding-top-10 contain_upload">
		<top :bgWhite='true'></top>
		<div class="content_upload bg-white padding-bottom-20 ">
			<div class=" padding-lr-30 padding-top-20 fs24 solid-bottom padding-bottom-15">
				作品上传
			</div>
			<div class="flex align-center justify-center padding-top-50 padding-lr-30 height420">
				<quill-editor ref="text" v-model="content" class="myQuillEditor w100" :options="editorOption" />
			</div>
			<div class=" margin-top-140 margin-bottom-40 flex align-center justify-center">
				<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
					@click="submitForm()">下一步</span>
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'authen',
		components: {
			bottom,
			top,
			quillEditor
		},
		data() {
			return {
				authenParams:{},
				content: '',//富文本内容
				editorOption: {
					placeholder: "请输入正文"
				}
			}
			// editorOption里是放图片上传配置参数用的，例如：
			// action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址
			// methods: 'post',  // 必填参数 图片上传方式
			// token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
			// name: 'upload_file',  // 必填参数 文件的参数名
			// size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
			// accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式
		},
		mounted() {
			this.authenParams = this.$route.params
		},
		methods: {
			submitForm() {
				if(!this.$refs.text.value){
					this.$message.error('请先输入作品内容');
					return false;
				};
				this.authenParams.body = this.$refs.text.value;
				this.$router.replace({
					name: 'memberDate',
					params:this.authenParams
				})
			},
		}
	}
</script>

<style scoped>
	.contain_upload {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.content_upload {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}

	.myQuillEditor {
		height: 400px;
	}
</style>
